import { createSignal, Show, } from "solid-js";

import { IconButton, Stack, Grid, Divider, } from "@suid/material";

import { CloseIcon  } from "../icons"
import { Tags } from '../SmallComponents/SmallTagsComponent';
import { MainLeftButtons } from '../Workflow/WfActionsComponent';
import { AccountStatusChip } from '../SmallComponents/SmallChips';
import { PageListMenu } from '../SmallComponents/SmallListMenu';
import { MenuItemType } from "../../../utils/menuUtil"

import { MainListComponent } from '../SmallComponents/SmallListComponent'
import { getText } from '../SmallComponents/Translate'

interface CustomerJobMenuType {
    canAdd?: boolean;
    canEdit?: boolean;
    canDelete?: boolean;
    canSelect?: boolean;
    showActivities?: boolean;
    showClose?: boolean;
    showMenu?: boolean;
}

const CustomerJobMenu = (props: CustomerJobMenuType) => {
    const [openFilter, setOpenFilter] = createSignal(false);
    const [showMenu] = createSignal(!props?.showMenu ? false : true);
    const [showActivities] = createSignal(props?.showActivities ? true : false);
    const [showCloseButton] = createSignal(props?.showClose ? true : false);

    const handleOpenFilter = () => {
        setOpenFilter(true);
    }
    const handleCloseFilter = () => {
        setOpenFilter(false);
    }
    const handleClose = () => {
    window.history.go(-1); return false;
}   

    const jobMenu: MenuItemType[] = [
        { actionType: "select", value: "selectjob", label: "Select Job", icon: "select", itemType: "action", handleMenuClick: handleOpenFilter },
        { actionType: "add", value: "addjob", label: "Add Job", icon: "add", itemType: "route", routeName: "/customerjobadd" },
        { actionType: "edit", value: "editjob", label: "Edit Job", icon: "edit", itemType: "route", routeName: "/customerjobroleedit" },
        { value: "d1", label: "", itemType: "divider", routeName: "" },
        { actionType: "other", value: "openroles", label: "Open roles are visible", itemType: "switch", routeName: "" },
    ];

    const txtHeaderactive = getText("jobpage", "headeractive")
    const txtJobVisible = getText("jobpage", "jobvisible")

    return (
        <>
            <Grid container   >
                <Grid item xs={12} md={6}  >
                    <Show
                        when={openFilter() === true}>
                        <MainListComponent openAsDialog={openFilter()} defaultFilterValue="active" type="customerjob" handleCloseEvent={handleCloseFilter} />
                    </Show>
                    <Stack direction="row" justifyContent="space-between"  >
                        {/* Addition navigation options for the customer */}
                        <Show when={showCloseButton() ===true}>
                            <IconButton onClick={handleClose} > <CloseIcon /> </IconButton>
                        </Show>
                        <Show when={showMenu() ===true}>
                        <PageListMenu options={jobMenu} handleEvent={handleOpenFilter} />
                        </Show>
                        <Show when={showActivities()===true}>
                            <MainLeftButtons />
                            <AccountStatusChip status="error" >{txtHeaderactive()}</AccountStatusChip>
                        </Show>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Stack direction="row" justifyContent="right" alignContent="center" margin={1} spacing={1}>
                        <Show when={showActivities()===true}>
                            <Tags />
                            {/* Present Candidates account status */}
                        </Show>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
        </>
    );
}
export { CustomerJobMenu }